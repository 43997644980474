import React from 'react'
import '../App.css';
import Info from './Info'

export default function Services() {
  return (
    <div>
      
        {/* Call To Action Section Start */}
        <Info/>
    </div>
  )
}
